'use client';

import React from 'react';
import { TextField, Button, Box, Select, MenuItem, CircularProgress, FormControlLabel, Checkbox, FormHelperText, Typography, Link, LinearProgress } from '@mui/material';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { useFormState, useFormStatus } from 'react-dom';
import { submitWaitlist } from '@/app/actions/waitlist';
type FormState = {
  message: string;
  error?: undefined;
} | {
  error: string;
  message?: undefined;
};
const initialState: FormState = {
  message: '',
  error: undefined
};
function SubmitButton() {
  const {
    pending
  } = useFormStatus();
  return <Button type="submit" variant="contained" color="primary" fullWidth disabled={pending} sx={{
    marginTop: 2,
    height: 48,
    position: 'relative',
    overflow: 'hidden',
    color: '#000000',
    '&:hover': {
      color: '#000000'
    }
  }} data-sentry-element="Button" data-sentry-component="SubmitButton" data-sentry-source-file="WaitlistFormUI.tsx">
      {pending ? <>
          <Typography sx={{
        opacity: 0.9,
        color: '#000000'
      }}>Submitting...</Typography>
          <LinearProgress sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 3,
        backgroundColor: 'rgba(0,0,0,0.1)'
      }} />
        </> : 'Join the Waiting List'}
    </Button>;
}
function SuccessConfirmation() {
  return <Box display="flex" flexDirection="column" alignItems="center" gap={2} py={4} data-sentry-element="Box" data-sentry-component="SuccessConfirmation" data-sentry-source-file="WaitlistFormUI.tsx">
      <CheckCircleOutline sx={{
      fontSize: 64,
      color: 'primary.main'
    }} data-sentry-element="CheckCircleOutline" data-sentry-source-file="WaitlistFormUI.tsx" />
      <Typography variant="h4" align="center" data-sentry-element="Typography" data-sentry-source-file="WaitlistFormUI.tsx">
        Thank You for Joining!
      </Typography>
      <Typography variant="body1" align="center" data-sentry-element="Typography" data-sentry-source-file="WaitlistFormUI.tsx">
        You've been added to our waitlist. We'll keep you updated on our progress and notify you when we launch.
      </Typography>
    </Box>;
}
export function WaitlistFormUI() {
  const [state, formAction] = useFormState(submitWaitlist, initialState);
  const [consent, setConsent] = React.useState(false);
  const [consentError, setConsentError] = React.useState(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!consent) {
      setConsentError(true);
      return;
    }
    setConsentError(false);
    formAction(new FormData(e.currentTarget));
  };
  if (state?.message) {
    return <SuccessConfirmation />;
  }
  return <Box component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" gap={2} width="100%" position="relative" data-sentry-element="Box" data-sentry-component="WaitlistFormUI" data-sentry-source-file="WaitlistFormUI.tsx">
      {useFormStatus().pending && <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      bgcolor: 'rgba(0,0,0,0.4)',
      backdropFilter: 'blur(4px)',
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 1
    }}>
          <CircularProgress size={36} />
        </Box>}

      <TextField name="email" label="Email Address" variant="outlined" type="email" fullWidth required error={!!state?.error} helperText={state?.error} slotProps={{
      htmlInput: {
        autoComplete: 'email'
      }
    }} aria-required aria-invalid={!!state?.error} data-sentry-element="TextField" data-sentry-source-file="WaitlistFormUI.tsx" />

      <Select name="interest" defaultValue="" displayEmpty fullWidth data-sentry-element="Select" data-sentry-source-file="WaitlistFormUI.tsx">
        <MenuItem value="" disabled data-sentry-element="MenuItem" data-sentry-source-file="WaitlistFormUI.tsx">
          Select Interest Level (Optional)
        </MenuItem>
        <MenuItem value="hobbyist" data-sentry-element="MenuItem" data-sentry-source-file="WaitlistFormUI.tsx">Hobbyist</MenuItem>
        <MenuItem value="small-grower" data-sentry-element="MenuItem" data-sentry-source-file="WaitlistFormUI.tsx">Small-Scale Grower</MenuItem>
        <MenuItem value="commercial" data-sentry-element="MenuItem" data-sentry-source-file="WaitlistFormUI.tsx">Commercial Cultivator</MenuItem>
        <MenuItem value="investor" data-sentry-element="MenuItem" data-sentry-source-file="WaitlistFormUI.tsx">Investor</MenuItem>
        <MenuItem value="other" data-sentry-element="MenuItem" data-sentry-source-file="WaitlistFormUI.tsx">Other</MenuItem>
      </Select>

      <FormControlLabel control={<Checkbox checked={consent} onChange={e => {
      setConsent(e.target.checked);
      setConsentError(false);
    }} name="consent" />} label={<Typography variant="body2">
            I agree to receive email updates about WeMush and related products/services. You can unsubscribe at any time.{' '}
            <Link href="/privacy" target="_blank" rel="noopener">
              Read our Privacy Policy
            </Link>
          </Typography>} data-sentry-element="FormControlLabel" data-sentry-source-file="WaitlistFormUI.tsx" />
      {consentError && <FormHelperText error>
          Please accept the terms to continue
        </FormHelperText>}

      <SubmitButton data-sentry-element="SubmitButton" data-sentry-source-file="WaitlistFormUI.tsx" />
    </Box>;
}