import(/* webpackMode: "eager", webpackExports: ["WaitlistFormUI"] */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/components/waitlist-form/WaitlistFormUI.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/@mui/material/Link/Link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/@mui/material/Paper/Paper.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/@mui/material/Typography/Typography.js");
